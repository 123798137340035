import React, { useState } from 'react';
import Cubes from './Games/Cubes';
let soundtrack = new Audio("./assets/soundtrack.mp3")

function Enigma() {

  const [found, setFound] = useState(false);
  const [checkedPN, setCheckedPN] = useState(false);
  const [firstClick, setFirstClick] = useState(false)

  function handleSubmit(){
    setFirstClick(true)
    if(checkedPN){
      soundtrack.volume = 0.3;
      soundtrack.currentTime = 0
      soundtrack.play()
      soundtrack.addEventListener('ended', function() {
        this.currentTime = 0;
        this.play();
      }, false);
      setFound(true)
    }
  }

  return (
    <>
    
    {/* {!found && <Scanner setFound={setFound} />} */}
    {found && <Cubes soundtrack={soundtrack}/>}
    {/* {found && <PenguinGame />} */}

    {/* <div style={{opacity: '0.5'}} className='animate-fade2'>
      <a style={{position: 'absolute', bottom: '0px', left: '5px', color:'#fff', fontSize: '10px', textDecoration: 'underline'}} href='https://www.bimbo.com.mx/es/terminos-y-condiciones' target='_blank'>Términos y condiciones</a>
      <a style={{position: 'absolute', bottom: '0px', right: '5px', color:'#fff', fontSize: '10px', textDecoration: 'underline'}} href='https://privacy.grupobimbo.com/mexico/policy/sitiosweb/index.html' target='_blank'>Aviso de privacidad</a>
      <p style={{position: 'absolute', bottom: '0px', right: '50%', color:'#fff', fontSize: '10px', transform: 'translateX(50%)'}}>"come bien"</p>
    </div> */}

    {!found && <>
      <div style={{ height: "100svh", width: "100vw", position: "absolute", backgroundImage: "url(./assets/back.png)", backgroundSize: "cover", backgroundRepeat: 'no-repeat', backgroundPositionX: 'center', opacity: 0.5 }} className='animate-fade2'></div>
        <div style={{ width: '100vw', height: '100svh'}} className='transition-all duration-1000'>
            <div className="hud animate-fade w-[20%] md:w-[15%] lg:w-[10%]" style={{ position: 'absolute', top: '5px', left: '50%', transform: 'translateX(-50%)' }}>
              <img src="./assets/logoenigma.png" alt="" style={{width: '100%'}}/>
              
            </div>
            <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  textAlign: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  padding: "20px",
                  borderRadius: "10px"
                }}
                className="animate-fade w-[70%] md:w-[50%] lg:w-[40%]"
              >
                <div className="relative" >

                  <ul className={`justify-center w-full md:w-1/2 mx-auto`}>
                      <li>
                          <img src="./assets/info.png" alt="" className="w-16 mx-auto animate-ping2"/>
                          <input type="radio" id="hosting-big" name="hosting" value="hosting-big" className="hidden peer" onChange={() => setCheckedPN(true)} checked={checkedPN} />
                          <label htmlFor='hosting-big' className={`inline-flex p-1 items-center justify-center w-full my-2 text-gray-50 rounded-xl peer-checked:border-blue-600 peer-checked:text-blue-60 ${!checkedPN && firstClick ? 'outline-dashed outline-2 -outline-offset-1 outline-red-700 bg-[#ff00006e]': 'outline-none'}`}>
                              <div className="block">
                                  <div className="w-full text-md md:text-md lg:text-2xl font-bloomer">Esta experiencia esta diseñada para personas mayores de 18 años. </div>
                                  <div className="w-full text-xs md:text-md lg:text-lg font-bloomer">Para continuar, marca la casilla indicando que eres mayor de edad. </div>
                                  <svg className={`mt-2 mx-auto w-10 h-10 rtl:rotate-180 hover:text-gray-300 hover:scale-110 transition-all cursor-pointer`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                  <path stroke="currentColor" strokeWidth="1.5" d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"/>
                                    {checkedPN && <path d="M8.5 12.5L10.5 14.5L15.5 9.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>}
                                  </svg>
                              </div>
                              
                          </label>
                      </li>
                  </ul>

                </div>
                <button
                onClick={() => handleSubmit()}
                  style={{
                    padding: "10px 20px",
                    fontSize: "20px",
                    cursor: "pointer",
                    backgroundColor: "#8c6e90",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    marginTop: "10px",
                  }}
                  className="animate-wiggle font-bloomer"
                >
                  Iniciar Enigma
                </button>
              </div>
        </div>
      </>}
    </>
  );
}

export default Enigma;
