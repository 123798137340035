import React, { useRef, useState, useEffect } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import * as THREE from "three";
import { motion } from "framer-motion-3d";
import { useNavigate } from "react-router-dom";
let audio = new Audio("./assets/hit.wav")

interface ImageBoxProps {
  position: [number, number, number];
  textureUrl: string;
  visible: boolean;
  onClick: (point: THREE.Vector3, index: number) => void;
  index: number;
  speed: number;
}

const ImageBox: React.FC<ImageBoxProps> = ({ position, textureUrl, visible, onClick, index, speed }) => {
  const planeRef = useRef<THREE.Mesh>(null!);

  const orbitParamsRef = useRef({
    orbitRadiusX: Math.random() * 5 + 6,
    orbitRadiusY: Math.random() * 5 + 10,
    orbitSpeedX: Math.random() * speed * 0.1 + 0.01,
    orbitSpeedY: Math.random() * speed * 0.1 + 0.01,
    initialPhaseX: Math.random() * Math.PI * 2,
    initialPhaseY: Math.random() * Math.PI * 2,
  });

  useEffect(() => {
    const loader = new THREE.TextureLoader();
    loader.load(textureUrl, (loadedTexture) => {
      if (planeRef.current && planeRef.current.material) {
        const material = planeRef.current.material as THREE.MeshBasicMaterial;
        material.map = loadedTexture;
        material.needsUpdate = true;
      }
    });
  }, [textureUrl, visible]);

  useFrame(({ camera }) => {
    if (visible && planeRef.current) {
      planeRef.current.lookAt(camera.position);

      const time = performance.now() * 0.15;
      const { orbitRadiusX, orbitRadiusY, orbitSpeedX, orbitSpeedY, initialPhaseX, initialPhaseY } = orbitParamsRef.current;
      const x = orbitRadiusX * Math.sin(time * orbitSpeedX + initialPhaseX);
      const y = orbitRadiusY * Math.cos(time * orbitSpeedY + initialPhaseY);

      planeRef.current.position.set(position[0] + x, position[1] + y, position[2]);
    }
  });

  return visible ? (
    <motion.group
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ duration: 1 }}
    >
      <mesh
        ref={planeRef}
        position={position}
        onClick={(event) => onClick(event.point, index)}
      >
        <planeGeometry args={[5, 5]} />
        <meshBasicMaterial transparent alphaTest={0.5} />
      </mesh>
    </motion.group>
  ) : null;
};

interface ParticleProps {
  position: [number, number, number];
}

const Particle: React.FC<ParticleProps> = ({ position }) => {
  const ref = useRef<THREE.Mesh>(null!);
  const [birthTime] = useState(() => performance.now());

  useFrame(() => {
    const elapsedTime = (performance.now() - birthTime) / 1000;

    if (elapsedTime > 1) {
      ref.current.visible = false;
    } else {
      ref.current.position.x += elapsedTime * (Math.random() < 0.5 ? 1 : -1);
      ref.current.position.y += elapsedTime * (Math.random() < 0.5 ? 1 : -1);
    }
  });

  return (
    <mesh ref={ref} position={position}>
      <boxGeometry args={[1, 1, 1]} />
      <meshBasicMaterial color={0x8c6e90} />
    </mesh>
  );
};

const Scene: React.FC<{
  totalTargets: number;
  handleScore: () => void;
  handleComplete: () => void;
  complete: boolean;
  speed: number;
}> = ({ totalTargets, handleScore, handleComplete, complete, speed }) => {
  const [visibility, setVisibility] = useState<boolean[]>(Array(totalTargets).fill(true));
  const [particles, setParticles] = useState<ParticleProps[]>([]);

  const handleBoxClick = (point: THREE.Vector3, index: number) => {
    if (complete) return;

    setVisibility((prev) => {
      const newVisibility = [...prev];
      newVisibility[index] = false;
      return newVisibility;
    });

    setParticles((prev) => [...prev, { position: [point.x, point.y, point.z] }]);

    audio.play()

    handleScore();

    if (visibility.filter((v, i) => v && i !== index).length === 0) {
      handleComplete();
    }
  };

  useEffect(() => {
    setVisibility(Array(totalTargets).fill(true));
  }, [totalTargets]);

  return (
    <group>
      {[...Array(totalTargets)].map((_, i) => (
        <ImageBox
          key={i}
          index={i}
          position={[0, 0, 0]}
          textureUrl={`./assets/pastel${i}.png`}
          visible={visibility[i]}
          onClick={handleBoxClick}
          speed={speed}
        />
      ))}
      {particles.map((particle, index) => (
        <Particle key={index} position={particle.position} />
      ))}
    </group>
  );
};

const Cubes: React.FC<{
  soundtrack: HTMLAudioElement;
}> = ({soundtrack}) => {
  const [level, setLevel] = useState(1);
  const [score, setScore] = useState(0);
  const [totalTargets, setTotalTargets] = useState(7);
  const [speed, setSpeed] = useState(0.01);
  const [complete, setComplete] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [userAnswer, setUserAnswer] = useState("");
  const [userAnswers, setUserAnswers] = useState("");
  const [showFinalMessage, setShowFinalMessage] = useState(false);
  const navigate = useNavigate();
  const totalLevels = 3;
  const comments = ["mango", "cookies", "frutos"];

  const handleScore = () => {
    setScore((prev) => prev + 1);
  };

  const handleComplete = () => {
    setComplete(true);
    setShowMessage(true);
  };

  const handleNextLevel = () => {
    if (level < totalLevels) {
      setLevel((prev) => prev + 1);
      setTotalTargets((prev) => prev + 1);
      setSpeed((prev) => prev + 0);
      setScore(0);
      setComplete(false);
      setShowMessage(false);
      setUserAnswer(""); // Limpiar la respuesta del usuario
    } else {
      setShowFinalMessage(true);
    } 
  };

  useEffect(() => {
    if(showFinalMessage){
      console.log(userAnswers.substring(1));
      const data = new FormData();
      let currentdate = new Date(); 
      let datetime = currentdate.getDate() + "/"
                + (currentdate.getMonth()+1)  + "/" 
                + currentdate.getFullYear() + " "  
                + currentdate.getHours() + ":"  
                + currentdate.getMinutes() + ":" 
                + currentdate.getSeconds();
      data.append('Fecha', datetime)
      data.append('Nivel 1', userAnswers.substring(1).split(',')[0].toUpperCase())
      data.append('Nivel 2', userAnswers.substring(1).split(',')[1].toUpperCase())
      data.append('Nivel 3', userAnswers.substring(1).split(',')[2].toUpperCase())
      fetch('https://script.google.com/macros/s/AKfycbwsH9DpnHSvGWMmOugDKkuWxT_W_EQBEFS5icNt09_gh60g9nMcuRIfj7q5Hdd1ImE8/exec', {
        method: 'POST',
        body: data
      })
    }
  }, [userAnswers]);

  const handleSubmitAnswer = () => {
    if (userAnswer.trim()) {
      setUserAnswers(`${userAnswers},${userAnswer.trim()}`)
      handleNextLevel();
    }
  };

  const startGame = () => {
    setGameStarted(true);
  };

  const resetGame = () => {
    setLevel(1);
    setTotalTargets(7);
    setSpeed(0.01);
    setScore(0);
    setComplete(false);
    setShowMessage(false);
    setShowFinalMessage(false);
    setGameStarted(false);
    setUserAnswer("");
    setUserAnswers("");
  };

  return (
    <div className="" style={{ height: "100svh", width: "100vw", position: "relative", backgroundImage: "url(./assets/back.png)", backgroundSize: "cover", backgroundRepeat: 'no-repeat', backgroundPositionX: 'center', backgroundPositionY: 'center' }}>
      
      <div
            style={{
              position: "absolute",
              top: "10px",
              left: "10px",
              color: "white",
            }}
            className="animate-fade w-10 lg:w-14 hover:scale-110 transition-all cursor-pointer z-50"
            onClick={()=> {soundtrack.pause() ; navigate('/')}}
          >
            <img src="./assets/home.png" alt="home"/>
      </div>
      
      {!gameStarted && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            padding: "20px",
            borderRadius: "10px"
          }}
          className="animate-fade w-[70%] md:w-[50%] lg:w-[40%]"
        >
          <h1 style={{ color: "white", fontSize: '20px' }} className="font-bloomer">Bienvenido al Enigma</h1>
          <p style={{ color: "white", fontSize: '14px' }} className="font-bloomer">Haz tap sobre los pastelitos para descubrir los sabores secretos.</p>
          <br />
          <div className="relative mb-10">
            <img src="./assets/pastel0.png" alt="" className="w-24 mx-auto"/>
            <div className="w-20 absolute animate-ping2" style={{top: '80%', left: '50%' , transform: "translateX(-50%)"}}>
              <img src="./assets/index.png" alt="" className="w-100" style={{top: '50%', left: '50%' , transform: "translate(-50%, -50%)"}}/>
            </div>
          </div>
          <br />
          <p style={{ color: "white", fontSize: '14px' }} className="font-bloomer">¡Buena suerte!</p>
          <button
            onClick={startGame}
            style={{
              padding: "10px 20px",
              fontSize: "20px",
              cursor: "pointer",
              backgroundColor: "#8c6e90",
              color: "white",
              border: "none",
              borderRadius: "5px",
              marginTop: "10px",
            }}
            className="animate-wiggle font-bloomer"
          >
            Comenzar
          </button>
        </div>
      )}

      {gameStarted && (
        <>
          <Canvas camera={{ position: [0, 10, 30] }}>
            <ambientLight />
            <Scene
              totalTargets={totalTargets}
              handleScore={handleScore}
              handleComplete={handleComplete}
              complete={complete}
              speed={speed}
            />
          </Canvas>

          <div
            style={{
              position: "absolute",
              top: "10px",
              left: "50%",
              transform: 'translateX(-50%)',
              color: "white",
            }}
            className="animate-fade font-bloomer text-md md:text-lg lg:text-2xl"
          >
            Nivel: {level}/{totalLevels}
          </div>

          {<div className="hud animate-fade  w-[20%] md:w-[15%] lg:w-[10%]" style={{ position: 'absolute', top: '5px', right: '10px'}}>
            <img src="./assets/logoenigma.png" alt="" style={{ width: '100%' }} />
          </div>}

          {!showFinalMessage && <div style={{ position: 'absolute', bottom: complete ? '35%' : '40px', left: '50%', width: comments[level - 1] === 'mango' ? '50%' : '90%', transform: 'translateX(-50%)' }} className="transition-all duration-1000 animate-fade">
            <img src={`./assets/${comments[level - 1]}${score}.png`} alt="" className="w-[100%] md:w-[70%] lg:w-[50%] xl:w-[40%] block mx-auto"/>
          </div>}

          {showMessage && !showFinalMessage && (
            <div
              style={{
                position: "absolute",
                top: "40%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                padding: "20px",
                borderRadius: "10px"
              }}
              className="animate-fade w-[70%] md:w-[50%] lg:w-[40%]"
            >
              {(comments[level - 1] === 'mango') &&<>
                <h1 style={{ color: "white", fontSize: '20px' }} className="font-bloomer">¿Resolviste el enigma?</h1>
                <p style={{ color: "white", fontSize: '14px' }} className="font-bloomer">¿Qué sabor tiene? </p>
              </>}
              {(comments[level - 1] === 'cookies') &&<>
                <h1 style={{ color: "white", fontSize: '20px' }} className="font-bloomer">¿Adivinaste el enigma?</h1>
                <p style={{ color: "white", fontSize: '14px' }} className="font-bloomer">¿De qué sabor es? </p>
              </>}
              {(comments[level - 1] === 'frutos') &&<>
                <h1 style={{ color: "white", fontSize: '20px' }} className="font-bloomer">¿Descubriste el último enigma? </h1>
              </>}
              
              <input
                type="text"
                value={userAnswer}
                onChange={(e) => setUserAnswer(e.target.value)}
                style={{
                  padding: "10px",
                  width: "80%",
                  marginTop: "10px",
                  marginBottom: "10px",
                  borderRadius: '10px',
                  color: "#8c6e90",
                }}
                className="text-center "
              />
              <br />
              <button
                onClick={handleSubmitAnswer}
                style={{
                  padding: "10px 20px",
                  fontSize: "20px",
                  cursor: "pointer",
                  backgroundColor: "#8c6e90",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  marginTop: "10px",
                }}
                className="animate-wiggle font-bloomer"
              >
                Continuar
              </button>
            </div>
          )}

          {showFinalMessage && (
            <>
              <div
                style={{
                  position: "absolute",
                  top: "35%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  textAlign: "center",
                  width: '50%'
                }}
                className="animate-fade"
              >
                <img src="./assets/terminado.png" alt="" className="animate-wiggle mx-auto"/>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "60%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  textAlign: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  padding: "20px",
                  borderRadius: "10px"
                }}
                className="animate-fade w-[70%] md:w-[50%] lg:w-[40%]" 
              >
                <h1 style={{ color: "white", fontSize: '20px' }} className="font-bloomer">¡Gracias por participar!</h1>
                <p style={{ color: "white", fontSize: '14px' }} className="font-bloomer">Quédate pendiente para resolver el próximo Enigma.</p>
                <button
                  onClick={resetGame}
                  style={{
                    padding: "10px 20px",
                    fontSize: "20px",
                    cursor: "pointer",
                    backgroundColor: "#8c6e90",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    marginTop: "10px",
                  }}
                  className="animate-wiggle font-bloomer"
                >
                  Reiniciar
                </button>
              </div>
            </>
          )}
        </>
      )}

    <div style={{opacity: '0.5'}} className='animate-fade2'>
      <a className="font-bloomer" style={{position: 'absolute', bottom: '0px', left: '5px', color:'#fff', fontSize: '10px', textDecoration: 'underline'}} href='https://www.bimbo.com.mx/es/terminos-y-condiciones' target='_blank'>Términos y condiciones</a>
      <a className="font-bloomer" style={{position: 'absolute', bottom: '0px', right: '5px', color:'#fff', fontSize: '10px', textDecoration: 'underline'}} href='https://privacy.grupobimbo.com/mexico/policy/sitiosweb/index.html' target='_blank'>Aviso de privacidad</a>
      <p className="font-bloomer" style={{position: 'absolute', bottom: '0px', right: '50%', color:'#fff', fontSize: '10px', transform: 'translateX(50%)'}}>"come bien"</p>
    </div>

    </div>
  );
};

export default Cubes;
